import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './text-editor.css';
import useTextEditor from './text-editor-hooks';

export default function TextEditor({
  label,
  setValue,
  value,
  editorValue,
  setEditorValue,
  placeholder,
  height,
  edit,
  error,
  helperText,
}) {
  const { editorState, onEditorStateChange, uploadImageCallBack } =
    useTextEditor({
      value,
      setValue,
      editorValue,
      setEditorValue,
      edit,
    });

  return (
    <Box>
      <InputLabel>{label}</InputLabel>
      <Editor
        placeholder={placeholder}
        //  toolbarStyle={{border: "1px solid #cdccd6"}}
        editorStyle={{
          overflowY: 'scroll',
          padding: '0 20px 0 20px',
          height: `${height}px`,
          color: 'var(--primary-font-color)',
          fontSize: '15px',
          // lineHeight: '1px',
          borderRadius: '6px',
        }}
        wrapperStyle={{
          // height: `${height}px`,
          width: '100%',
          border: error
            ? '1px solid var(--error-color)'
            : '1px solid var(--color-silver)',
          borderRadius: '6px',
        }}
        toolbarStyle={{
          marginBottom: '0',
          borderBottom: '1px solid var(--secondary-border-color)',
          padding: '5px 5px 0 5px',
          borderRadius: '6px 6px 0 0',
        }}
        editorState={editorState}
        // toolbarClassName={styles.toolbarClass}
        // wrapperClassName={styles.wrapperClass}
        // editorClassName={styles.editorClass}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['blockType', 'inline', 'list', 'image', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline'],
            bold: { icon: '/images/bold-icon.svg' },
            italic: { icon: '/images/italic.svg' },
            underline: { icon: '/images/underline.svg' },
          },
          blockType: {
            className: styles.toolbarIcon,
            inDropdown: true,
            selectedValue: 'Heading',
            options: ['Heading', 'Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
          },
          list: {
            inDropdown: false,
            options: ['indent', 'outdent', 'unordered', 'ordered'],
            indent: { icon: '/images/right-indent.svg' },
            outdent: { icon: '/images/left-indent.svg' },
            unordered: { icon: '/images/bulleted-list.svg' },
            ordered: { icon: '/images/numbered-list.svg' },
          },
          // image: {
          //   icon: '/images/image.svg',
          //   urlEnabled: true,
          //   uploadEnabled: true,
          //   uploadCallback: uploadImageCallBack,
          //   alignmentEnabled: true,
          //   previewImage: true,
          //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
          //   alt: { present: false, mandatory: false },
          //   defaultSize: {
          //     height: 'auto',
          //     width: 'auto',
          //   },
          // },
          link: {
            icon: '/images/link.svg',
            inDropdown: false,
            className: styles.iconborder,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: ['link'],
          },
        }}
      />
      {error ? (
        <Typography
          variant='body2'
          sx={{
            color: 'var(--error-color)',
          }}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
}

TextEditor.propTypes = {
  label: PropTypes.string,
  setValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  sx: PropTypes.object,
  height: PropTypes.number,
  value: PropTypes.element,
  editorValue: PropTypes.object.isRequired,
  setEditorValue: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

TextEditor.defaultProps = {
  label: '',
  placeholder: '',
  sx: {},
  height: 300,
  value: <span />,
  edit: false,
  error: false,
  helperText: null,
};
