import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Stack, Typography, Grid, Alert } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from '@mui/lab/LoadingButton';

import TextField from '../text-field';
import { Formik } from 'formik';
import * as Yup from 'yup';

import useAddExternalContact from './add-external-contact-hooks';

const AddExternalContact = ({ selectedItems, setSelectedItems }) => {
  const {
    formikRef,
    emailData,
    onSubmitFormikHandler,
    deleteEmailQuery,
    tableContainerRef,
    addContacts,
  } = useAddExternalContact({ selectedItems, setSelectedItems });
  return (
    <Box sx={{ width: '100%' }}>
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        sx={{ marginBottom: '8px', display: 'flex' }}
      >
        <Typography
          variant='body2'
          sx={{ fontWeight: '900', fontSize: '14px', marginBottom: '5px' }}
        >
          Add External Contacts
        </Typography>
        <Alert
          severity='info'
          sx={{
            height: '34px',
            alignItems: 'center',
            borderRadius: '4px',
            fontSize: '12px',
            lineHeight: '12px',
            marginBottom: '18px',
            flexGrow: '1',
            '& .MuiAlert-icon': {
              marginRight: '8px',
              fontSize: '18px',
            },
          }}
        >
          They will not be able to place order, unless added into the system
        </Alert>
      </Stack>

      <Stack spacing={1.5} sx={{ border: '1px solid #CDCCD6' }}>
        <Stack spacing={0.5} sx={{ padding: '12px' }}>
          <Typography variant='body2'>ADD CONTACTS:</Typography>
          <Stack direction='row' spacing={2}>
            <Formik
              innerRef={formikRef}
              validateOnMount
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Invalid Email Format')
                  .required('Please enter Email'),
              })}
              initialValues={{ email: '' }}
              onSubmit={onSubmitFormikHandler}
            >
              {({ ...rest }) => {
                return (
                  <TextField
                    label=''
                    placeholder='Enter Email'
                    sx={{ width: '300px' }}
                    id='email'
                    name='email'
                    value={rest.values.email}
                    onChange={rest.handleChange}
                    error={
                      rest.touched.email &&
                      Boolean(
                        rest.errors.email !== '' && rest?.errors.email
                          ? true
                          : false
                      )
                    }
                    helperText={rest.touched.email && rest.errors.email}
                    onBlur={() => {
                      if (rest?.errors?.email) rest?.setFieldError('email', '');
                    }}
                  />
                );
              }}
            </Formik>

            <LoadingButton
              variant='contained'
              loading={addContacts?.isLoading ? true : false}
              onClick={() => {
                formikRef.current.submitForm();
              }}
              onBlur={() => {
                if (formikRef?.current?.errors?.email)
                  formikRef?.current?.setFieldError('email', '');
              }}
              sx={{ width: 'fit-content' }}
            >
              Add
            </LoadingButton>
          </Stack>
        </Stack>
        <Box
          ref={tableContainerRef}
          sx={{
            maxHeight: '145px',
            width: '100%',
            overflowY: 'scroll',
            marginTop: '0px !important',
            borderTop: '1px solid var(--primary-border-color)',
          }}
        >
          {emailData?.map((email, i) => (
            <Stack
              direction='row'
              spacing={1}
              key={i}
              sx={{
                borderBottom: '1px solid var(--primary-border-color)',
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: '6px 16px' }}
                      checked={(() => {
                        var x = false;
                        selectedItems.forEach((item) => {
                          if (item.id === email.id) x = true;
                        });
                        return x;
                      })()}
                      onClick={() => {
                        if (selectedItems.some((obj) => obj.id === email.id)) {
                          setSelectedItems(() =>
                            selectedItems.filter((item) => item.id !== email.id)
                          );
                        } else {
                          setSelectedItems((prev) => {
                            return [
                              ...prev,
                              {
                                id: email.id,
                                contact_email: email.contact_email,
                              },
                            ];
                          });
                        }
                      }}
                    />
                  }
                  label={email.contact_email}
                />
              </FormGroup>
            </Stack>
          ))}
        </Box>
        <Stack
          direction='row'
          spacing={1}
          sx={{
            paddingLeft: '12px',
            marginTop: '0px !important',
            paddingTop: '12px',
            paddingBottom: '12px',
          }}
        >
          <Button
            variant='outlined'
            sx={{
              width: 'fit-content',
              marginTop: '0px !important',
            }}
            onClick={() => deleteEmailQuery.mutate()}
          >
            Remove Selected
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
AddExternalContact.propTypes = {
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
};
AddExternalContact.defaultProps = {
  selectedItems: [],
  setSelectedItems: () => {},
};
export default AddExternalContact;
