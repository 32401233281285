import React from 'react';
import PropTypes from 'prop-types';
import MyModal from 'components/modals/modal';
import { Typography, Button, Stack } from '@mui/material';
import SelectOld from 'components/select/select.old';
import { useSelectSenderSignature } from './select-sender-signature-hooks';
import SendingEmailsModal from 'components/share/components/sending-emails-modal';
import { responsiveBreakpoints } from 'utils/utils';

const SelectSenderSignature = ({
  selectedSender,
  setSelectedSender,
  selectedSignature,
  setSelectedSignature,
  setMessageAndSubject,
}) => {
  const { emailListingQuery } = useSelectSenderSignature(
    setSelectedSender,
    setSelectedSignature,
    setMessageAndSubject
  );
  const { mobile } = responsiveBreakpoints();
  return (
    <Stack
      sx={{ width: mobile ? '100%' : '20%' }}
      spacing={mobile ? 1 : 4}
      direction='row'
    >
      <SelectOld
        label=''
        placeholder='Select a Sender'
        allowRemoveIcon={false}
        value={
          selectedSender !== ''
            ? selectedSender
            : {
                label: emailListingQuery?.data?.sender[0].name,
                value: emailListingQuery?.data?.sender[0].id,
              }
        }
        setValue={setSelectedSender}
        options={(() => {
          var arr = [];
          emailListingQuery?.data?.sender?.forEach((data) => {
            arr.push({
              label: data.name,
              value: data.id,
            });
          });
          return arr;
        })()}
      />

      <SelectOld
        label=''
        placeholder='Select a Sender'
        allowRemoveIcon={false}
        value={
          selectedSignature !== ''
            ? selectedSignature
            : {
                label: emailListingQuery?.data?.signature[0].name,
                value: emailListingQuery?.data?.signature[0].id,
              }
        }
        setValue={setSelectedSignature}
        options={(() => {
          var arr = [];
          emailListingQuery?.data?.signature?.forEach((data) => {
            arr.push({
              label: data.name,
              value: data.id,
            });
          });
          return arr;
        })()}
      />
    </Stack>
  );
};
SelectSenderSignature.propTypes = {
  selectedSender: PropTypes.string,
  setSelectedSender: PropTypes.func,
  selectedSignature: PropTypes.string,
  setSelectedSignature: PropTypes.func,
  setMessageAndSubject: PropTypes.func,
};
SelectSenderSignature.defaultProps = {
  selectedSender: '',
  setSelectedSender: () => {},
  selectedSignature: '',
  setSelectedSignature: () => {},
  setMessageAndSubject: () => {},
};
export default SelectSenderSignature;
