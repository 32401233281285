import React from 'react';
import PropTypes from 'prop-types';

import {
  Stack,
  Button,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material';

import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

import BuyerEmptyStateIcon from 'assets/icons/buyer-empty-state-icon';
import PlusSignIcon from 'assets/icons/plus-sign-icon';

import TextField from 'components/text-field';
import SelectOld from 'components/select/select.old';
import MultiSelect from 'components/select/multi-select';
import EmptyState from 'components/empty-state';
import CreateCustomerGroupModal from 'components/modals/create-group/create-customer-group-modal';
import AddContacts from './add-contacts';

import useSelectBuyersTable from './select-buyers-hooks';

import { responsiveBreakpoints } from 'utils/utils';
import { selectBuyerViewByOptions } from '../constant';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default function SelectBuyerTable({
  selectedBuyers,
  setSelectedBuyers,
  worksheetId,
  sendinviteEmail,
  selectedAssociates,
  setSelectedAssociates,
  selectedSender,
  setSelectedSender,
  selectedSignature,
  setSelectedSignature,
  showComponents,
  isAllBuyerSelected,
  setIsAllBuyerSelected,
  groups,
  setGroups,
  salesRep,
  setSalesRep,
  isSelectAll,
  setIsSelectAll,
  emailValue,
  setEmailValue,
  setTotalBuyersSelected,
  setMessageAndSubject,
  selectedExternalContacts,
  setSelectedExternalContacts,
}) {
  const {
    options,
    handleCheck,
    buyersList,
    searchValue,
    setSearchValue,
    alphabetValue,
    setAlphabetValue,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    onPageChangeHandler,
    groupOptions,
    isLoading,
    showCreateCustomerGroupModal,
    seShowCreateCustomerGroupModal,
    onCreateGroupSaveHandler,
    getBuyers,
    setPage,
  } = useSelectBuyersTable({
    selectedBuyers,
    setSelectedBuyers,
    worksheetId,
    sendinviteEmail,
    groups,
    setGroups,
    setIsAllBuyerSelected,
    isAllBuyerSelected,
    salesRep,
    setSalesRep,
    isSelectAll,
    setIsSelectAll,
    emailValue,
    setTotalBuyersSelected,
  });
  const { mobile } = responsiveBreakpoints();
  const alphabets = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];
  let alphaButtonArray = [];
  alphaButtonArray.push(
    <Button
      variant='text'
      sx={
        alphabetValue === '1'
          ? {
              color: '#0066c0',
              textDecoration: 'underline',
              fontWeight: '900',
              width: '23px',
              height: '23px',
            }
          : { fontWeight: '900', width: '23px', height: '23px' }
      }
      onClick={(e) => {
        e.preventDefault();
        setAlphabetValue('1');
        setPage('0');
        getBuyers({
          id: worksheetId,
          searchTerm: searchValue,
          salesRepId: salesRep?.value ?? '',
          alphaSort: '1',
          page: '0',
          rowsPerPage: rowsPerPage,
          groups: groups,
          emailValue: emailValue,
        });
      }}
    >
      #
    </Button>
  );
  alphabets.forEach((item) =>
    alphaButtonArray.push(
      <Button
        variant='text'
        sx={
          alphabetValue === item
            ? {
                color: '#0066c0',
                textDecoration: 'underline',
                fontWeight: '900',
                width: '23px',
                height: '23px',
              }
            : {
                fontWeight: '900',
                width: '23px',
                height: '23px',
              }
        }
        onClick={(e) => {
          e.preventDefault();
          setAlphabetValue(item);
          setPage('0');
          getBuyers({
            id: worksheetId,
            searchTerm: searchValue,
            salesRepId: salesRep?.value ?? '',
            alphaSort: item,
            page: '0',
            rowsPerPage: rowsPerPage,
            groups: groups,
            emailValue: emailValue,
          });
        }}
      >
        {item.toUpperCase()}
      </Button>
    )
  );
  alphaButtonArray.push(
    <Button
      variant='text'
      sx={
        alphabetValue === ''
          ? {
              color: '#0066c0',
              textDecoration: 'underline',
              fontWeight: '900',
              width: '23px',
              height: '23px',
            }
          : { fontWeight: '900', width: '23px', height: '23px' }
      }
      onClick={(e) => {
        e.preventDefault();
        setAlphabetValue('');
        getBuyers({
          id: worksheetId,
          searchTerm: searchValue,
          salesRepId: salesRep?.value ?? '',
          alphaSort: '',
          page: page,
          rowsPerPage: rowsPerPage,
          groups: groups,
          emailValue: emailValue,
        });
      }}
    >
      All
    </Button>
  );
  return (
    <Box sx={{ overflow: 'hidden', width: '100%' }}>
      {/* {isLoading ? (
        <Box
          width='100vw'
          height='100vh'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
            opacity: 0.4,
            backgroundColor: '#000',
          }}
        >
          <CircularProgress />
        </Box>
      ) : null} */}
      {showCreateCustomerGroupModal ? (
        <CreateCustomerGroupModal
          open={showCreateCustomerGroupModal}
          setOpen={seShowCreateCustomerGroupModal}
          edit={false}
          sendApiRequest={(
            groupNameValue,
            groupDescriptionValue,
            selectedBuyers,
            isSelectAll
          ) => {
            let payload = {
              name: groupNameValue,
              description: groupDescriptionValue,
              buyerIds: selectedBuyers,
              isAllBuyerSelected: isSelectAll,
            };
            onCreateGroupSaveHandler(payload);
          }}
        />
      ) : null}

      <Stack spacing={1}>
        <Stack
          direction={mobile ? 'column ' : 'row'}
          // justifyContent='space-between'
          alignItems='center'
          spacing={1}
          sx={[
            mobile
              ? { display: 'flex', flexDirection: 'column', rowGap: '8px' }
              : null,
          ]}
        >
          <Stack
            direction='row'
            spacing={1}
            sx={{ overflowX: 'auto', width: mobile ? '100%' : 'fit - content' }}
          >
            {/* setSalesrep */}
            <Box sx={{ width: '115px' }}>
              <SelectOld
                placeholder='Sort By'
                variant='outlined'
                color='secondary'
                value={salesRep}
                setValue={(value) => {
                  setSalesRep(value);
                  getBuyers({
                    id: worksheetId,
                    searchTerm: searchValue,
                    salesRepId: value?.value ?? '',
                    alphaSort: alphabetValue,
                    page: page,
                    rowsPerPage: rowsPerPage,
                    groups: groups,
                    emailValue: emailValue,
                    clearSelectedBuyers: false,
                  });
                }}
                allowRemoveIcon={false}
                options={options}
              />
            </Box>
            {/* groups */}
            <Box sx={{ width: '115px' }}>
              <MultiSelect
                placeholder='Group'
                value={groups}
                setValue={(value) => {
                  setGroups(value);
                  getBuyers({
                    id: worksheetId,
                    searchTerm: searchValue,
                    salesRepId: salesRep?.value ?? '',
                    alphaSort: alphabetValue,
                    page: page,
                    rowsPerPage: rowsPerPage,
                    groups: value,
                    emailValue: emailValue,
                    clearSelectedBuyers: true,
                  });
                }}
                options={groupOptions ?? []}
                valueAccessor='id'
                labelAccessor='name'
                defaultOption={
                  <Stack
                    direction='row'
                    spacing={1.3}
                    onClick={() => {
                      seShowCreateCustomerGroupModal(true);
                    }}
                    sx={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                      padding: '7px 5px 5px 5px',
                      alignItems: 'center',
                    }}
                  >
                    <PlusSignIcon
                      fill='var(--theme-color)'
                      style={{ width: '12px', height: '12px' }}
                    />
                    <Typography
                      variant='body2'
                      sx={{ color: 'var(--theme-color)' }}
                    >
                      Create Group
                    </Typography>
                  </Stack>
                }
              />
            </Box>

            {/* <Link
            component='button'
            variant='body2'
            onClick={() => {
              seShowCreateCustomerGroupModal(true);
            }}
            sx={{
              fontSize: '11px',
              textUnderlineOffset: '6px',
              whiteSpace: 'nowrap',
            }}
          >
            Create Group
          </Link> */}
          </Stack>

          {/* alpha sort */}
          <Stack
            direction='row'
            sx={[
              mobile
                ? {
                    fontWeight: '900',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    overflowX: 'auto',
                    order: '3',
                  }
                : {
                    fontWeight: '900',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    columnGap: '0px',
                  },
            ]}
          >
            {alphaButtonArray}
          </Stack>
          <TextField
            placeholder='Search'
            value={searchValue}
            onChange={(e) => {
              e.preventDefault();
              setSearchValue(e.target.value);
              setPage('0');
            }}
            inputSize={mobile ? '100%' : '200px'}
            sx={
              mobile
                ? {
                    order: '2',
                    marginBottom: 0,
                    backgroundColor: (() => {
                      let bgcolor = '';
                      searchValue != '' ? (bgcolor = '#cfe2ff') : '';
                      return bgcolor;
                    })(),
                  }
                : {
                    marginBottom: 0,
                    backgroundColor: (() => {
                      let bgcolor = '';
                      searchValue != '' ? (bgcolor = '#cfe2ff') : '';
                      return bgcolor;
                    })(),
                  }
            }
          />
        </Stack>

        {/* pagination table */}
        <Box>
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{
              padding: '6px 15px',
              border: '1px solid #E3E7ED',
              flexWrap: 'wrap',
            }}
          >
            <Stack
              direction='row'
              spacing={mobile ? 0 : 1}
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <Checkbox
                checked={isSelectAll}
                onClick={() => {
                  if (isSelectAll) {
                    let buyerSdataIds = buyersList?.data.map(
                      (item) => item.buyer_id
                    );
                    let filteredTemp = selectedBuyers.filter(
                      (item) => !buyerSdataIds.includes(item.buyer_id)
                    );
                    setSelectedBuyers(filteredTemp);
                    if (isAllBuyerSelected) {
                      setIsAllBuyerSelected(false);
                    }
                  } else {
                    let uniqueBuyerIds = {};
                    let buyersArray = [];
                    buyersArray = [
                      ...selectedBuyers,
                      ...(buyersList?.data ?? []),
                    ];
                    let uniqueArray = buyersArray.filter((obj) => {
                      if (!uniqueBuyerIds[obj.buyer_id]) {
                        uniqueBuyerIds[obj.buyer_id] = true;
                        return true;
                      }
                      return false;
                    });
                    setSelectedBuyers(uniqueArray);
                  }

                  setIsSelectAll(!isSelectAll);
                }}
              />
              <Typography variant='body1' sx={{ whiteSpace: 'nowrap' }}>
                {isSelectAll
                  ? isAllBuyerSelected
                    ? ` All ${buyersList?.count ?? ''} buyers are selected`
                    : `(${selectedBuyers.length} Buyers Selected)`
                  : `(${selectedBuyers.length} Buyers Selected)`}
              </Typography>
              <Button
                variant='text'
                sx={{
                  color: '#008CD6',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => {
                  if (isAllBuyerSelected) {
                    setSelectedBuyers([]);
                    setIsSelectAll(false);
                    setIsAllBuyerSelected(false);
                  } else {
                    let uniqueBuyerIds = {};
                    let buyersArray = [];
                    buyersArray = [
                      ...selectedBuyers,
                      ...(buyersList?.data ?? []),
                    ];
                    let uniqueArray = buyersArray.filter((obj) => {
                      if (!uniqueBuyerIds[obj.buyer_id]) {
                        uniqueBuyerIds[obj.buyer_id] = true;
                        return true;
                      }
                      return false;
                    });
                    setSelectedBuyers(uniqueArray);
                    setIsSelectAll(true);
                    setIsAllBuyerSelected(true);
                  }
                }}
              >
                {isAllBuyerSelected
                  ? `Undo`
                  : ' Select All Buyers from the list'}
              </Button>
            </Stack>
            {sendinviteEmail ? (
              <Stack
                spacing={1}
                direction='row'
                sx={{
                  direction: 'row',
                  height: 'fit-content',
                  alignItems: 'center',
                }}
              >
                <Typography variant='body1' fontWeight='500'>
                  View By
                </Typography>
                <Box
                  sx={{
                    width: mobile ? '115px' : '220px',
                  }}
                >
                  <SelectOld
                    placeholder='Activity'
                    variant='outlined'
                    color='secondary'
                    value={emailValue}
                    sxMenu={{ maxHeight: '430px' }}
                    setValue={(value) => {
                      setEmailValue(value);
                      getBuyers({
                        id: worksheetId,
                        searchTerm: searchValue,
                        salesRepId: salesRep?.value ?? '',
                        alphaSort: alphabetValue,
                        page: page,
                        rowsPerPage: rowsPerPage,
                        groups: groups,
                        emailValue: value,
                      });
                    }}
                    allowRemoveIcon={false}
                    options={selectBuyerViewByOptions}
                  />
                </Box>
              </Stack>
            ) : null}
          </Stack>

          <TableContainer
            sx={{
              maxHeight: '400px',
              backgroundColor: 'var(--paper-background-color)',
            }}
          >
            <Table
              size='small'
              sx={{ border: '1px solid var(--secondary-border-color)' }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>ACCOUNT NAME</TableCell>
                  <TableCell>ACCOUNT#</TableCell>
                  <TableCell>EMAIL ADDRESS</TableCell>
                  <TableCell>DBA</TableCell>
                  <TableCell>BUYER</TableCell>
                  <TableCell>CITY</TableCell>
                  <TableCell>STATE</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colspan='8'>
                      <Box
                        sx={{
                          height: '400px',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : null}

                {buyersList?.data &&
                  buyersList?.data.map((buyer, i) => (
                    <TableRow key={i}>
                      {/* <TableCell sx={{ fontWeight: 900 }}>{row.accname}</TableCell> */}
                      <TableCell sx={{ fontWeight: '900px' }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ padding: '0 10px 0 10px' }}
                                checked={(() => {
                                  var x = false;
                                  selectedBuyers.forEach((item) => {
                                    if (item.buyer_id === buyer.buyer_id)
                                      x = true;
                                  });
                                  return x;
                                })()}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleCheck(buyer);
                                }}
                              />
                            }
                            label={buyer.aname}
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell>{buyer.acct}</TableCell>
                      <TableCell>{buyer.email}</TableCell>
                      <TableCell>{buyer.dba}</TableCell>
                      <TableCell>{buyer.last}</TableCell>
                      <TableCell>{buyer.city}</TableCell>
                      <TableCell>{buyer.state}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                {buyersList?.count === 0 ? (
                  <TableCell colspan='8'>
                    {' '}
                    <EmptyState
                      title='No Records found'
                      subTitle='We couldnt find any Records. Try adjusting your filters to display results '
                      icon={<BuyerEmptyStateIcon />}
                      sx={{
                        border: '1px solid var(--secondary-border-color)',
                        height: '210px',
                      }}
                    />
                  </TableCell>
                ) : null}
              </TableFooter>
            </Table>
          </TableContainer>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'right',
              border: '1px solid var(--secondary-border-color)',
            }}
          >
            <TablePagination
              rowsPerPageOptions={[
                25, 50, 100 /*{ label: 'All', value: buyersList?.count }*/,
              ]}
              count={buyersList?.count}
              onPageChange={onPageChangeHandler}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              ActionsComponent={TablePaginationActions}
              border={0}
            />
          </Box>
        </Box>
        <AddContacts
          selectedAssociates={selectedAssociates}
          setSelectedAssociates={setSelectedAssociates}
          selectedSender={selectedSender}
          setSelectedSender={setSelectedSender}
          selectedSignature={selectedSignature}
          setSelectedSignature={setSelectedSignature}
          showComponents={showComponents}
          setMessageAndSubject={setMessageAndSubject}
          selectedExternalContacts={selectedExternalContacts}
          setSelectedExternalContacts={setSelectedExternalContacts}
        />
      </Stack>
      {/* Pagination table ends */}
    </Box>
  );
}

SelectBuyerTable.propTypes = {
  selectedBuyers: PropTypes.array.isRequired,
  setSelectedBuyers: PropTypes.func.isRequired,
  worksheetId: PropTypes.string.isRequired,
  sendinviteEmail: PropTypes.bool,
  selectedAssociates: PropTypes.array.isRequired,
  setSelectedAssociates: PropTypes.func.isRequired,
  selectedSender: PropTypes.string,
  setSelectedSender: PropTypes.func,
  selectedSignature: PropTypes.string,
  setSelectedSignature: PropTypes.func,
  showComponents: PropTypes.object,
  isAllBuyerSelected: PropTypes.bool,
  setIsAllBuyerSelected: PropTypes.func,
  groups: PropTypes.array,
  setGroups: PropTypes.func,
  salesRep: PropTypes.any,
  setSalesRep: PropTypes.func,
  isSelectAll: PropTypes.bool,
  setIsSelectAll: PropTypes.func,
  emailValue: PropTypes.string,
  setEmailValue: PropTypes.func,
  setTotalBuyersSelected: PropTypes.func,
  setMessageAndSubject: PropTypes.func,
  selectedExternalContacts: PropTypes.array,
  setSelectedExternalContacts: PropTypes.func,
};
SelectBuyerTable.defaultProps = {
  sendinviteEmail: false,
  selectedAssociates: [],
  setSelectedAssociates: () => {},
  selectedSender: '',
  setSelectedSender: () => {},
  selectedSignature: '',
  setSelectedSignature: () => {},
  showComponents: {},
  isAllBuyerSelected: false,
  setIsAllBuyerSelected: () => {},
  groups: [],
  setGroups: () => {},
  salesRep: '',
  setSalesRep: () => {},
  isSelectAll: false,
  setIsSelectAll: () => {},
  emailValue: '',
  setEmailValue: () => {},
  setTotalBuyersSelected: () => {},
  setMessageAndSubject: () => {},
  selectedExternalContacts: [],
  setSelectedExternalContacts: () => {},
};
