import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { responsiveBreakpoints } from 'utils/utils';
export default function SendEmailActions({ actions, emailSentText }) {
  const { mobile } = responsiveBreakpoints();
  return (
    <Stack
      direction='row'
      spacing={2}
      justifyContent={mobile ? 'center' : 'right'}
      alignItems='center'
      sx={[
        {
          borderTop: '1px solid var(--secondary-border-color)',
          padding: '7px 25px 7px 25px',
          backgroundColor: 'var(--modal-header-color)',
          display: 'flex',
          flexWrap: 'wrap',
          rowGap: '8px',
          width: '100%',
        },
        // size === 'fullWidth'
        //   ? {
        //       position: 'absolute',
        //       bottom: 0,
        //       padding: '7px 100px 7px 100px',
        //     }
        //   : null,
      ]}
    >
      <Typography variant='h2' sx={{ fontWeight: '900', textAlign: 'center' }}>
        {emailSentText}
      </Typography>
      <Stack direction='row' spacing={0.5}>
        {actions.map((action, i) => {
          return <Box key={i}>{action}</Box>;
        })}
      </Stack>
    </Stack>
  );
}
SendEmailActions.propTypes = {
  actions: PropTypes.array,
  emailSentText: PropTypes.string,
};
SendEmailActions.defaultProps = { actions: null, emailSentText: '' };
