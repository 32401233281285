import { React } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Delete from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlusSignIcon from '../../../assets/icons/plus-sign-icon';
import DraftsQuickPanelIcon from '../../../assets/icons/drafts-quick-panel-icon';
import useDraftsQuickPanel from './drafts-quick-panel-hooks';
import useDrafts from 'pages/drafts/drafts-hooks';
import CreateNewDraftModal from 'components/modals/create-draft/create-new-draft-modal';
import Confirm from 'components/modals/confirmation-modal';
import CartIcon from 'assets/icons/cart-icon';
import EmptyState from 'components/empty-state';
import BuyerEmptyStateIcon from 'assets/icons/buyer-empty-state-icon';
import { useUpdateURLParams } from 'common-hooks/update-query-params-hooks';
import { zIndex } from 'constants/zIndex';
import HeaderCartIcon from 'assets/icons/header-cart-icon';

export default function DraftsQuickPanel({
  mouseOver,
  mouseOut,
  setOpen,
  open,
}) {
  const { updateURLParams } = useUpdateURLParams();
  const {
    drafts,
    sharedDrafts,
    getDrafts,
    getSharedDrafts,
    deleteDraft,
    createDraft,
    setDefaultDraft,
    showCreateNewDraftModal,
    setShowCreateNewDraftModal,
  } = useDrafts();

  const {
    selectedId,
    setSelectedId,
    navigate,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
  } = useDraftsQuickPanel({ getDrafts, getSharedDrafts });

  const confirmDelete = (
    <Button
      variant='contained'
      onClick={(e) => {
        e.preventDefault();
        updateURLParams({ resetCatalog: true });
        deleteDraft(selectedId, setOpen);
        setShowDeleteConfirmation(false);
      }}
    >
      Yes, Delete
    </Button>
  );

  return (
    <Fade in={open} timeout={700}>
      <Paper
        elevation={3}
        sx={{
          position: 'absolute',
          top: '46px',
          right: 0,
          width: '460px',
          borderRadius: '6px',
          height: '570px',
          zIndex: zIndex.popover,
          overflow: 'hidden',
          border: '1px solid var(--secondary-border-color)',
        }}
        onMouseEnter={mouseOver}
        onMouseLeave={mouseOut}
      >
        {/*delete confirmation modal*/}
        {showDeleteConfirmation ? (
          <Confirm
            open={showDeleteConfirmation}
            setOpen={setShowDeleteConfirmation}
            title='Are you sure?'
            message='Are you sure you want to delete this draft.'
            action={confirmDelete}
          />
        ) : null}

        {/*create new draft name modal*/}

        <CreateNewDraftModal
          open={showCreateNewDraftModal}
          setOpen={setShowCreateNewDraftModal}
          createDraft={createDraft}
          quickPanelHandleClose={setOpen}
          redirect={true}
        />

        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            borderBottom: '1px solid var(--secondary-border-color)',
            padding: '0 20px 0 20px',
            backgroundColor: 'var(--modal-header-color)',
          }}
        >
          <Typography
            variant='body2'
            sx={{ fontSize: '14px', fontWeight: 700 }}
          >
            Draft Orders
          </Typography>
          <Button
            variant='text'
            startIcon={
              <PlusSignIcon
                fill='#0066c0'
                style={{ width: '15px', height: '15px' }}
              />
            }
            sx={{ fontSize: '12px', color: '#0066c0' }}
            onClick={(e) => {
              e.preventDefault();
              setShowCreateNewDraftModal(true);
            }}
          >
            New Draft
          </Button>
        </Stack>

        {drafts || sharedDrafts ? (
          <>
            {drafts?.data?.length > 0 || sharedDrafts?.data?.length > 0 ? (
              <Stack sx={{ overflow: 'scroll', maxHeight: '500px' }}>
                {drafts?.data?.map((item, i) => (
                  <>
                    {!item?.isSharedDraft && (
                      <Grid
                        key={i}
                        container
                        sx={[
                          {
                            padding: '0 20px 5px 20px',
                            borderBottom:
                              '1px solid var(--secondary-border-color)',
                          },
                          {
                            '&:hover': {
                              backgroundColor: '#f4f5f7',
                              cursor: 'pointer',
                            },
                          },
                          item.viewed
                            ? {
                                borderLeft: '3px solid',
                                borderColor: 'primary.main',
                                borderBottom:
                                  '1px solid var(--secondary-border-color)',
                              }
                            : {},
                        ]}
                        onClick={(e) => {
                          e.preventDefault();
                          setDefaultDraft(item.id);
                          navigate(`/drafts/${item.id}`);
                        }}
                      >
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '7px',
                          }}
                        >
                          <DraftsQuickPanelIcon
                            active={item.viewed}
                            fill='rgba(0, 0, 0, 0.6)'
                          />
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          sm={9}
                          style={{
                            padding: '7px 0 4px 14px',
                          }}
                        >
                          <Stack
                            direction='row'
                            spacing={1}
                            alignItems='center'
                          >
                            <Typography
                              variant='body2'
                              textAlign='left'
                              noWrap
                              sx={
                                ([
                                  item.viewed
                                    ? { color: 'primary.main' }
                                    : { color: 'var(--primary-font-color)' },
                                ],
                                {
                                  fontSize: '13px',
                                  fontWeight: 700,
                                })
                              }
                            >
                              {item.name.toUpperCase()}
                            </Typography>
                            {item.description === 'Special Order' ? (
                              <Chip
                                label='Override'
                                variant='outlined'
                                size='small'
                                color='primary'
                                sx={{
                                  height: '20px',
                                  fontSize: '12px',
                                  marginLeft: '10px',
                                  fontWeight: 500,
                                }}
                              />
                            ) : null}
                          </Stack>

                          <Stack
                            direction='row'
                            spacing={2}
                            sx={{
                              padding: '5px 0 1px 0',
                            }}
                          >
                            <Typography
                              variant='body1'
                              style={{
                                color: 'rgba(77, 70, 99, 0.7)',
                              }}
                            >
                              PRODUCTS:{' '}
                              <span
                                style={{
                                  fontSize: '12px',
                                  color: 'var(--primary-font-color)',
                                }}
                              >
                                {item.products}
                              </span>
                            </Typography>
                            <Typography
                              variant='body1'
                              style={{
                                color: 'rgba(77, 70, 99, 0.7)',
                              }}
                            >
                              DELIVERIES:{' '}
                              <span
                                style={{
                                  fontSize: '12px',
                                  color: 'var(--primary-font-color)',
                                }}
                              >
                                {item.deliveries}
                              </span>
                            </Typography>
                          </Stack>
                          <Typography
                            textAlign='left'
                            sx={{
                              fontSize: '12px',
                              color: 'rgba(77, 70, 99, 0.7)',
                            }}
                          >
                            <AccessTimeIcon
                              sx={{
                                width: '12px',
                                height: '12px',
                                position: 'relative',
                                top: '2px',
                              }}
                            />{' '}
                            Created on {item.created}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/drafts/${item.id}`);
                              setOpen(false);
                            }}
                          >
                            {/* <CartIcon
                              width='17px'
                              height='18px'
                              sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                            /> */}
                            <HeaderCartIcon />
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton>
                            <Delete
                              fontSize='small'
                              sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSelectedId(item.id);
                                setShowDeleteConfirmation(true);
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                  </>
                ))}
                {sharedDrafts?.data?.length > 0 ? (
                  <Typography
                    variant='body2'
                    sx={{
                      fontSize: '13px',
                      fontWeight: 700,
                      padding: '10px 20px',
                    }}
                  >
                    Shared Draft Orders
                  </Typography>
                ) : null}
                {sharedDrafts?.data?.length > 0
                  ? sharedDrafts?.data?.map((item, i) => (
                      <Grid
                        key={i}
                        container
                        sx={[
                          {
                            padding: '0 20px 5px 20px',
                            borderBottom:
                              '1px solid var(--secondary-border-color)',
                          },
                          {
                            '&:hover': {
                              backgroundColor: '#f4f5f7',
                              cursor: 'pointer',
                            },
                          },
                          item.viewed
                            ? {
                                borderLeft: '3px solid',
                                borderColor: 'primary.main',
                                borderBottom:
                                  '1px solid rgba(204, 204, 204, 0.27)',
                              }
                            : {},
                        ]}
                        onClick={(e) => {
                          e.preventDefault();
                          setDefaultDraft(item.id);
                          navigate(`/drafts/${item.id}`);
                        }}
                      >
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '7px',
                          }}
                        >
                          <DraftsQuickPanelIcon
                            active={item.viewed ?? false}
                            fill='rgba(0, 0, 0, 0.6)'
                          />
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          sm={9}
                          style={{
                            padding: '7px 0 4px 14px',
                          }}
                        >
                          <Stack
                            direction='row'
                            spacing={1}
                            alignItems='center'
                          >
                            <Typography
                              variant='body2'
                              textAlign='left'
                              noWrap
                              sx={
                                ([
                                  item.viewed
                                    ? { color: 'primary.main' }
                                    : { color: 'var(--primary-font-color)' },
                                ],
                                {
                                  fontWeight: 700,
                                  fontSize: '13px',
                                })
                              }
                            >
                              {item.name.toUpperCase()}
                            </Typography>
                            {item.description === 'Special Order' ? (
                              <Chip
                                label='Override'
                                variant='outlined'
                                size='small'
                                color='primary'
                                sx={{
                                  height: '20px',
                                  fontSize: '13px',
                                  marginLeft: '10px',
                                  fontWeight: 500,
                                }}
                              />
                            ) : null}
                          </Stack>

                          <Stack
                            direction='row'
                            spacing={2}
                            sx={{
                              padding: '5px 0 1px 0',
                            }}
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                color: 'rgba(77, 70, 99, 0.7)',
                              }}
                            >
                              PRODUCTS:{' '}
                              <span
                                style={{
                                  fontSize: '12px',
                                  color: 'var(--primary-font-color)',
                                }}
                              >
                                {item.products}
                              </span>
                            </Typography>
                            <Typography
                              variant='subtitle2'
                              style={{
                                color: 'rgba(77, 70, 99, 0.7)',
                              }}
                            >
                              DELIVERIES:{' '}
                              <span
                                style={{
                                  fontSize: '12px',
                                  color: 'var(--primary-font-color)',
                                }}
                              >
                                {item.deliveries}
                              </span>
                            </Typography>
                          </Stack>
                          <Typography
                            textAlign='left'
                            sx={{
                              fontSize: '12px',
                              color: 'rgba(77, 70, 99, 0.7)',
                            }}
                          >
                            <AccessTimeIcon
                              sx={{
                                width: '12px',
                                height: '12px',
                                position: 'relative',
                                top: '2px',
                              }}
                            />{' '}
                            Created on {item.created}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/drafts/${item.id}`);
                              setOpen(false);
                            }}
                          >
                            {/* <CartIcon
                              width='17px'
                              height='18px'
                              sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                            /> */}
                            <HeaderCartIcon />
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton>
                            <Delete
                              fontSize='small'
                              sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedId(item.id);
                                setShowDeleteConfirmation(true);
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))
                  : null}
              </Stack>
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '500px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmptyState
                  title='No drafts created yet'
                  subTitle=''
                  icon={<BuyerEmptyStateIcon />}
                />
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Stack
          sx={{
            borderTop: '1px solid var(--secondary-border-color)',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            backgroundColor: 'var(--modal-header-color)',
          }}
        >
          <Button
            variant='text'
            sx={{
              fontSize: '12px',
              color: '#0066c0',
              fontWeight: '700',
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate('/drafts');
            }}
          >
            View All Drafts
          </Button>
        </Stack>
      </Paper>
    </Fade>
  );
}

DraftsQuickPanel.propTypes = {
  mouseOver: PropTypes.func.isRequired,
  mouseOut: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
